import appPkg from '../../package.json';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Config - App General
  APP: {
    ENV: 'DEV',
    PACKAGE_NAME: appPkg.name,
    VERSION: appPkg.version,
    URL: {
      PRIVATE: 'https://app-dxchub-dev.azurewebsites.net',
      PUBLIC: 'https://dxchub-dev.dxc.com'
    }
  },
  // Config - My profile/MyTeam powerBI report with dev url
  POWERBI: {
    REPORT_BASE_URL: 'https://app.powerbi.com/groups/db29efa1-5c0b-4fa9-9754-11b96d26c55e/reports/',
    EMBED_URL: 'https://app.powerbi.com/reportEmbed?reportId=',
    REPORT_CONFIG:
      '&autoAuth=true&ctid=93f33571-550f-43cf-b09f-cd331338d086&filterPaneEnabled=false&navContentPaneEnabled=false',
    GROUP_ID: 'db29efa1-5c0b-4fa9-9754-11b96d26c55e',
    REPORT_ID_PROFILE: '3a812141-1f5c-4672-b516-5bc1ce93dc15',
    REPORT_PAGE_SKILLS: '&pageName=ReportSection81bc3d41b7f046c57bb9',
    REPORT_PAGE_PROJECTS: '&pageName=ReportSectiona228e82e328cfba4c6c8',
    REPORT_PAGE_TIMETRACKING: '&pageName=ReportSection2e460b510c55351d6d50',
    REPORT_ID_MYTEAM: 'e37e7072-86aa-4871-adb2-c7ae7add76d9',
    REPORT_AUTO_AUTH_LOGIN:
      'https://app.powerbi.com/autoAuthLogin.cshtml?noSignUpCheck=1&ctid=93f33571-550f-43cf-b09f-cd331338d086',
    REPORT_ACCOUNT_EXECUTIVES: 'https://d3.dxc.com'
  },
  // Config - MS Azure resources
  AZURE: {
    APP_INSIGHTS: {
      API_URL: 'https://api.applicationinsights.io',
      API_VERSION: 'v1',
      CONFIG_INSTRUMENTATION_KEY: '7d31fb4e-837d-4c89-ad69-8e4a1d6329d9',
      CONFIG_APPLICATION_ID: '36c13555-8d83-43f6-b219-f622094f4de2'
    },
    AD: {
      CLIENT_ID: '835e2c89-1fd5-4c41-8aba-8d22e7f45255',
      TENANT_ID: '93f33571-550f-43cf-b09f-cd331338d086',
      AUTHORITY: 'https://login.microsoftonline.com/93f33571-550f-43cf-b09f-cd331338d086',
      APP_ID_URI: 'api://app-dxchub-dev.azurewebsites.net/835e2c89-1fd5-4c41-8aba-8d22e7f45255'
    }
  },
  // Config - MS Teams
  TEAMS: {
    APP_ID: '2b231380-7bdd-11eb-ab64-7580b0e0f230',
    MANIFEST_VERSION: '1.10'
  },
  // Config - MS Graph
  GRAPH: {
    URL: 'https://graph.microsoft.com',
    VERSIONS: {
      V1: 'v1.0',
      BETA: 'beta'
    }
  },
  // Config - API dependencies
  DEPS: {
    PROFILE_API: {
      URL: 'https://func-profiles-service-dev.azurewebsites.net/api'
    },
    SEARCHDATA_API: {
      URL: 'https://func-searchdata-service-dev.azurewebsites.net/api'
    },
    NEWS_API: {
      URL: 'https://func-news-service-dev.azurewebsites.net/api'
    },
    NOTIFICATIONS_API: {
      URL: 'https://func-notif-service-dev.azurewebsites.net/api'
    },
    UAM_NOTIFICATIONS_API: {
      URL: 'https://func-uam-notif-service-dev.azurewebsites.net/api'
    },
    APPROVAL_CENTRAL: {
      API_URL: 'https://itg.approval.central.dxc.com',
      APP_URL: 'https://approval-central-itg.dxc.com/'
    },
    DXCTIME: {
      URL: 'https://sb1.replicon.com/DXCSandbox/'
    },
    WORKDAY: {
      URL: 'https://impl.workday.com/dxctechnology3/d/unifiedinbox/initialinbox/2998$17139.htmld'
    },
    ADMIN_API: {
      URL: 'https://func-admin-service-dev.azurewebsites.net/api'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
